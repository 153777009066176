<template>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">VENTAS</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Administrar Cotizaciones</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Cotizaciones
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="estilos-form">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-1">
            <span><strong>Nº:</strong></span>
            <InputNumber
              :class="'text-right'"
              v-model="numero_proforma"
              mode="decimal"
              :minFractionDigits="0"
              :maxFractionDigits="0"
              :min="0"
              :max="999999"
              @keyup.enter="cargarProformas"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA INICIO:</strong></span>
            <input
              type="date"
              class="p-inputtext p-component"
              v-model="fecha_inicio"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA FIN:</strong></span>
            <input
              type="date"
              class="p-inputtext p-component"
              v-model="fecha_fin"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>CLIENTE: </strong></span>
            <div class="p-inputgroup">
              <AutoComplete
                field="cliente"
                v-model="clienteSelected"
                :suggestions="clientesListadoFiltrado"
                placeholder="Buscar Cliente"
                @keyup.enter="buscarCliente($event)"
              />
              <Button
                :disabled="!clienteSelected"
                icon="pi pi-search"
                class="p-button-warning"
                @click="buscarCliente($event)"
              />
            </div>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>ESTADO:</strong></span>
            <Dropdown
              v-model="estadoSelected"
              :options="estados"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione Estado"
              @change="cargarProformas"
            >
              <template #option="{ option }">
                <span :class="'product-badge ' + claseEstado[option.value]">
                  {{ option.label }}
                </span>
              </template>
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <span>&nbsp;</span>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-info"
              @click="cargarProformas"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <DataTable
        ref="dtproformas"
        :value="proformas"
        dataKey="id"
        :paginator="true"
        :rows="50"
        :loading="cargando"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 200]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Proformas"
        responsiveLayout="scroll"
        showGridlines
        v-model:expandedRows="expandedRows"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column expander style="width: 1rem"></Column>
        <Column field="numero" header="Nº"> </Column>
        <Column field="cliente" header="CLIENTE" style="font-weight: bold">
        </Column>
        <Column field="nombre_mercado" header="MERCADO"> </Column>
        <Column field="fecha" header="FECHA"> </Column>
        <Column field="vendedor" header="VENDEDOR"> </Column>
        <Column
          field="total"
          header="TOTAL"
          class="text-right"
          style="font-weight: bold; font-size: 1.1em"
        >
          <template #body="{ data }">
            {{ formatNumber(data.total) }}
          </template>
        </Column>
        <Column
          field="valido_hasta"
          header="VALIDO HASTA"
          style="text-align: center; font-weight: bold"
        >
          <template #body="{ data }">
            {{ dateValides(data.valido_hasta) }}
          </template>
        </Column>
        
        <Column field="estado" header="ESTADO" style="text-align: center">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ estadoTexto(data.estado) }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            ></Button>
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            ></Menu>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="p-3">
            <h5>
              <span style="border-bottom: 1px solid #ccc">
                DETALLE DE PROFORMA:
                <strong
                  >{{ slotProps.data.id }} -
                  {{ slotProps.data.cliente }}</strong
                >
              </span>
            </h5>
            <DataTable
              :value="slotProps.data.detalles"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              stripedRows
              showGridlines
              selectionMode="single click"
            >
              <Column field="id" header="COD_PROD" class="text-right"></Column>
              <Column
                field="descripcion"
                header="PRODUCTO/DESCRIPCIÓN"
                style="width: 300px; font-weight: bold"
              ></Column>
              <Column header="CANTIDAD" class="text-right">
                <template #body="slotProps">
                  {{ slotProps.data.cantidad }}
                </template>
              </Column>
              <Column header="PRECIO" class="text-right">
                <template #body="slotProps">
                  {{ convertirNumeroGermanicFormat(slotProps.data.precio) }}
                </template>
              </Column>
              <Column header="SUBTOTAL" class="text-right">
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.cantidad * slotProps.data.precio
                    )
                  }}
                </template>
              </Column>
              <Column header="DESCUENTO" class="text-right">
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(slotProps.data.descuento_neto)
                  }}
                </template>
              </Column>
              <Column header="TOTAL" class="text-right">
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.cantidad * slotProps.data.precio -
                        slotProps.data.descuento_neto
                    )
                  }}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import VentaService from "@/service/VentaService";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";
export default {
  data() {
    return {
      proformas: [],
      totalRegistros: 0,
      cargando: false,
      claseEstado: [
        "status-anulada", // 0: Anulada
        "status-vigente", // 1: Vigente
        "status-vendida", // 2: Vendida
        "status-vencida", // 3: Vencida
      ],
      numero_proforma: null,
      fecha_inicio: null,
      fecha_fin: null,
      estadoSelected: 1,
      estados: [
        { label: "VIGENTE", value: 1 },
        { label: "VENDIDA", value: 2 },
        { label: "VENCIDA", value: 3 },
        { label: "ANULADA", value: 0 },
      ],
      clienteSelected: null,
      clientesListadoFiltrado: [],
      expandedRows: [],
    };
  },
  ventaService: null,
  clienteService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.ventaService = new VentaService();
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.cargarProformas();
  },
  methods: {
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    formatNumber(value) {
      return new Intl.NumberFormat("es-BO", {
        style: "currency",
        currency: "BOB",
      }).format(value);
    },
    dateValides(fecha) {
      return new Date(fecha).toLocaleDateString();
    },
    estadoTexto(estado) {
      return ["ANULADA", "VIGENTE", "VENDIDA", "VENCIDA"][estado];
    },
    acciones(datos) {
      return [
        {
          label: "Ver Detalle",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Proforma Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/proforma/${datos.id}/detalle`,
        },
        {
          label: "Descargar PDF",
          disabled:
            "Proforma DescargarPDF" in this.auth.user.permissions
              ? false
              : true,
          icon: "pi pi-fw pi-file-pdf",
          command: () => this.imprimirProforma(datos.id),
        },
        {
          label: "Anular Proforma",
          icon: "pi pi-fw pi-trash",
          disabled:
            datos.estado == 0 || datos.estado == 2
              ? true
              : false || "Proforma Anular" in this.auth.user.permissions
              ? false
              : true,
          command: () => this.deleteProforma(datos.id),
        },
      ];
    },
    buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarClienteProforma({ texto: event.query })
          .then((response) => {
            this.clientesListadoFiltrado = [...response.clientes];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    deleteProforma(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea anular la Proforma?",
        header: "Anular Proforma",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "SI, ANULAR",
        rejectLabel: "CANCELAR",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.ventaService.anularProforma(id).then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Proforma Anulada",
              detail: response.mensaje,
              life: 3000,
            });
            this.cargarProformas();
          });
        },
      });
    },

    cargarProformas() {
      let datos = {
        numero: this.numero_proforma,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        estado: this.estadoSelected,
        cliente: this.clienteSelected ? this.clienteSelected : null,
      };
      this.cargando = true;
      this.ventaService.getAllProformas(datos).then((response) => {
        this.proformas = response.proformas || [];
        this.cargando = false;
      });
    },
    imprimirProforma(id) {
      this.ventaService
        .imprimirVentaProforma(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    expandAll() {
      this.expandedRows = this.proformas.filter((p) => p.id);
    },
    collapseAll() {
      this.expandedRows = null;
    },
  },
  watch: {
    proformas() {
      this.totalRegistros = this.proformas.length;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.product-badge {
  padding: 3px 5px;
  border-radius: 2px;
  font-weight: bold;
  color: white;
}

.status-anulada {
  background-color: red;
}

.status-vigente {
  background-color: green;
}

.status-vendida {
  background-color: orange;
}

.status-vencida {
  background-color: darkcyan;
}
.estilos-form {
  background-color: #ececec;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
</style>
